import React, {useState} from "react";
import { Modal, Divider, Typography, Image } from 'antd';
import {FormattedMessage} from "react-intl";
import classNames from "classnames";
import dises from '@assets/img/icon/dices';
console.log(dises)
const { Text } = Typography;

function CustomSelectModal({visible, onCancel, onConfirm}) {
    const [selectedDice, setSelectedDice] = useState("");

    return (
        <Modal title={<FormattedMessage id="select-dice"/>}
               visible={visible}
               onOk={() => {onConfirm(selectedDice); setSelectedDice("")}}
               onCancel={() => {setSelectedDice("");onCancel()}}
               centered={true}
               width={1000}
               okText={<FormattedMessage id="submit"/>}
               okButtonProps={{ disabled: !selectedDice , className: "miniBtn" }}
               cancelButtonProps={{className: "miniBtn default"}}
               cancelText={<FormattedMessage id="cancel"/>}>
            {
                Object.keys(dises).map((key, index) => (
                    <Image key={key}
                            src={dises[key]}
                            preview={false}
                            onClick={() => setSelectedDice(index)}
                            className={classNames("clickable", {selected: index === selectedDice})}
                            />
                ))
            }
        </Modal>
    )
}

export default CustomSelectModal