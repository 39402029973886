export default {
    settings: {
        lang: {
            id: 1
        }
    },
    user: {},
    emptyObj: {},
    emptyArr: [],
    playersList: {
        list: [],
        listModalVisible: false,
        isLoading: false
    },
    allowCancel: {
        allowBets: false,
        allowTransaction: false
    },
    card: null,
    timer:{
        timerStart: undefined,
        timerEnd: undefined
    },
    gameInfo: {
        roundId: ""
    }
}