import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import { Image } from "antd";



export default function Dice({ dice, ...props }) {

    return(<Image src={dice} {...props} preview={false}/>)
}
