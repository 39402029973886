import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_GAME_START,
    SET_ERROR_MSG,
    REQUEST_GAME_RESULT,
    SET_GAME_RESULT,
    REQUEST_GAME_CANCEL,
    SET_GAME_CANCEL,
    REQUEST_GAME_INFO,
    SET_GAME_START,
    SET_TIMER,
    SET_DICES,
    SET_SERVER_TIME,
    SET_WINNER,
    REQUEST_GAME_IS_ACTIVE,
    SET_GAME_IS_ACTIVE,
    REQUEST_EDIT_GAME_IS_ACTIVE,
    SET_EDIT_GAME_IS_ACTIVE,
    SET_GAME_INFO,
    SET_ROUND_INFO
} from "@actions/actionTypes";
import {GetResource, PostResource, PutResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";
import initialStore from "../initialStore";



function* fetchGameStart() {
    const info =  yield call(PostResource, `/round/start`);
    if (info.timerEnd && info.timerStart) {
        yield put({
            type: SET_TIMER,
            timer: {
                timerEnd: info.timerEnd,
                timerStart: info.timerStart,
            }
        });
    };
    if (info.time) {
        yield put({
            type: SET_SERVER_TIME,
            time: info.time
        });
    }
    yield put({
        type: SET_SERVER_TIME,
        winner: 0
    });

    yield put({type: SET_GAME_START});
    yield put({type: SET_ERROR_MSG, data: {type: 'info', messages: `game_status_is_start`}});
}

function* fetchGameResult() {
    yield call(PostResource, `/round/result`);
    yield put({type: SET_GAME_RESULT});
    yield put({
        type: SET_TIMER,
        timer: initialStore.timer
    });
    yield put({
        type: SET_DICES,
        code: initialStore.card
    });

    yield put({
        type: SET_WINNER,
        winner: 0
    });
    yield put({type: SET_ERROR_MSG, data: {type: 'info', messages: `game_status_is_result`}});
}

function* fetchGameCancel() {
    yield call(PostResource, `/round/cancel`);
    yield put({type: SET_GAME_CANCEL});
    yield put({
        type: SET_TIMER,
        timer: initialStore.timer
    });
    yield put({
        type: SET_DICES,
        code: initialStore.card
    });
    yield put({
        type: SET_WINNER,
        winner: 0
    });
    yield put({type: SET_ERROR_MSG, data: {type: 'info', messages: `game_is_canceled`}});
}

function* fetchGameInfo() {
    const info = yield call(GetResource, `/round/info`);
    
    if (info) {
        yield put({
            type: SET_TIMER,
            timer: {
                isReconnect: true,
                timerStart: info.start,
                timerEnd: info.end
            }
        });
    
        yield put({
            type: SET_DICES,
            code: info.card ? info.card : initialStore.card
        });
    
        if (info.time) {
            yield put({
                type: SET_SERVER_TIME,
                time: info.time
            });
        }
    }
    yield put({type: SET_ROUND_INFO, roundInfo: {roundId: info ? info.roundId : ""}});
    yield put({type: SET_GAME_INFO})
}

function* fetchGameIsActive({isActive}) {
    const res = yield call(GetResource, `/Game/isactive`);
    yield put({type: SET_GAME_IS_ACTIVE, isActive: res.isActive})
}

function* EditGameIsActive({isActive}) {
    const res = yield call(PutResource, `/Game/isactive`, {IsActive: isActive});
    yield put({type: SET_EDIT_GAME_IS_ACTIVE, isActive: res.isActive})
}

function* gameControlSaga() {
    yield takeLatest(REQUEST_GAME_START,safe(onError, fetchGameStart));
    yield takeLatest(REQUEST_GAME_RESULT,safe(onError, fetchGameResult));
    yield takeLatest(REQUEST_GAME_CANCEL,safe(onError, fetchGameCancel));
    yield takeLatest(REQUEST_GAME_INFO,safe(onError, fetchGameInfo));
    yield takeLatest(REQUEST_EDIT_GAME_IS_ACTIVE, safe(onError, EditGameIsActive));
    yield takeLatest(REQUEST_GAME_IS_ACTIVE, safe(onError, fetchGameIsActive));
}

export default gameControlSaga;