import dice1 from "./1.svg";
import dice2 from "./2.svg";
import dice3 from "./3.svg";
import dice4 from "./4.svg";
import dice5 from "./5.svg";
import dice6 from "./6.svg";

export default {
    dice1,
    dice2,
    dice3,
    dice4,
    dice5,
    dice6
} 