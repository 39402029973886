import React, {useEffect, useState, useRef, useLayoutEffect} from "react";
import {connect} from "react-redux";
import {gameCancel, gameStart, gameResult, gameInfo, editGameIsActive, gameIsActive} from "@actions/gameControl";
import {FormattedMessage} from "react-intl";
import {Button, Space, Row, Col, Switch} from "antd";
import Timer from "@components/Timer/Timer";
import {setErrorMsg} from "@actions/alertAction"
import { setDice } from "@actions/diceActions";
import classnames from "classnames";
import CustomSelectModal from "@components/CustomSelectModal/CustomSelectModal";
import {subscribeTickerWorker, unSubscribeTickerWorker, getTimeFromTickerWorker} from "@actions/workerActions";
import Dice from "@components/Dice";

function GameControl({
                         gameCancel,
                         gameStart,
                         gameResult,
                         gameInfo,
                         loading,
                         dices,
                         timer,
                         setErrorMsg,
                         winner,
                         setDice,
                         editGameIsActive,
                         gameIsActive,
                         isActive,
                         subscribeTickerWorker,
                         unSubscribeTickerWorker
                     }) {
    const [online, setOnline] = useState(navigator.onLine);
    const timeOut = useRef([])
    const checkIsActiveTimer = useRef([])
    const [showSelectModal, setShowSelectModal] = useState(0)
    const [betDuration, setBetDuration] = useState()
    const [ticker, setTicker] = useState(undefined);

    useEffect(() => {
        function enable(e) {
            setOnline(true)
        }
        function disable(e) {
            setOnline(false)
        }
        function getServerTime(e) {
            const time = JSON.parse(e.data);
            setBetDuration(time.data)
        }
        window.addEventListener('online', enable);
        window.addEventListener('offline', disable);
        subscribeTickerWorker(getServerTime);
        return () => {
            unSubscribeTickerWorker(getServerTime)
            window.removeEventListener('online', enable);
            window.removeEventListener('offline', disable);
        }
    },[])

    useEffect(() => {
        if (dices.length == 3) {
            setTicker(0)
        } else {
            setTicker(betDuration === undefined ? undefined : parseInt( betDuration / 1000))
        }

    }, [betDuration])

    useEffect(() => {
        if (online) {
            timeOut.current.forEach(time => clearInterval(time))
            timeOut.current = []
            checkIsActiveTimer.current.forEach(time => clearInterval(time))
            checkIsActiveTimer.current = []
            gameInfo()
        }
    },[online])

    useEffect(() => {
        if (!loading._game_info && online) {
            timeOut.current.push(setInterval(() => {gameInfo()}, 1000))
        }
        return () => {
            timeOut.current.forEach(time => clearInterval(time))
            timeOut.current = []
        }
    }, [loading._game_info])

    useEffect(() => {
        if (!loading._game_is_active && online) {
            checkIsActiveTimer.current.push(setInterval(() => {gameIsActive()}, 2000))
        }
        return () => {
            checkIsActiveTimer.current.forEach(time => clearInterval(time))
            checkIsActiveTimer.current = []
        }
    }, [loading._game_is_active])


    const autoFocus = () => {
        window.focus();
        if (document.activeElement) {
            document.activeElement.blur();
        }
    }


    const selectDice = (value, index) => {
        setDice(value, index);
        setShowSelectModal(0);
        getTimeFromTickerWorker();
    }

    useLayoutEffect(() => {
        autoFocus()
    }, [showSelectModal])

    return (<div className="content">
        <div className="titleArea">
            <div className="titleArea--title"><FormattedMessage id='game_control'/></div>
            <div className="titleArea--BtnGroup">
                <Switch loading={loading._game_is_active}
                        onClick={() => editGameIsActive(!isActive)}
                        disabled={false}
                        checked={isActive}/>
            </div>
        </div>
        <div className="game-control">
            {isActive ?
                <>
                    <Row className="game-control-row center" align="center">
                        <Col className={classnames("game-control-col", {clickable: !!timer.timerStart})} onClick={() => setShowSelectModal(!!timer.timerStart && 1)}>
                            <Dice dice={dices[0]}/>
                        </Col>
                        <Col className={classnames("game-control-col", {clickable: !!timer.timerStart})} onClick={() => setShowSelectModal(!!timer.timerStart && 2)}>
                            <Dice dice={dices[1]}/>
                        </Col>
                        <Col className={classnames("game-control-col", {clickable: !!timer.timerStart})} onClick={() => setShowSelectModal(!!timer.timerStart && 3)}>
                            <Dice dice={dices[2]}/>
                        </Col>
                    </Row>
                    <div>
                        <Row className="center">
                            <div className="custom-control">
                                <Timer />
                            </div>
                        </Row>
                        <Row  justify="space-between" align="center">
                            <Col span={8}  className="control-buttons"> </Col>
                            <Col span={8}  className="control-buttons">
                                <Space>
                                    <Button loading={loading._game_start}
                                            disabled={loading._game_result || loading._game_start || loading._game_cancel || dices.length == 3}
                                            className={classnames("miniBtn", {hidden: !!timer.timerStart})}
                                            size="large"
                                            onClick={gameStart}
                                            >
                                        <FormattedMessage id='start'/>
                                    </Button>
                                    <Button loading={loading._game_result}
                                            disabled={loading._game_result || loading._game_start || loading._game_cancel || !dices.length == 3}
                                            className={classnames("miniBtn", {hidden: !timer.timerStart})}
                                            size="large"
                                            onClick={gameResult}>
                                        <FormattedMessage id='result'/>
                                    </Button>
                                </Space>
                            </Col>
                            <Col span={8}  className="control-buttons">
                                <Space>
                                    <Button loading={loading._game_cancel}
                                            disabled={loading._game_result || loading._game_start || loading._game_cancel || !timer.timerStart}
                                            className="miniBtn"
                                            size="large"
                                            onClick={gameCancel}>
                                        <FormattedMessage id='cancel'/>
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                </> :
                <div className="message">
                    <FormattedMessage id="table_is_not_active"/>
                </div>}


        </div>

        <CustomSelectModal visible={showSelectModal}
                           onCancel={() => setShowSelectModal(0)}
                           onConfirm={selectDice}
        />
    </div>)
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        dices: state.dices,
        timer: state.timer,
        winner: state.winner,
        isActive: state.gameIsActive
    }
}

const mapDispatchToProps = {
    gameCancel,
    gameStart,
    gameResult,
    setDice,
    gameInfo,
    setErrorMsg,
    editGameIsActive,
    gameIsActive,
    subscribeTickerWorker,
    unSubscribeTickerWorker,
}

export default connect(mapStateToProps, mapDispatchToProps)(GameControl)