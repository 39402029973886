import {
    SET_DICES
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function dicesReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_DICES:
            return action.dises? action.dises : initialStore.emptyArr;
        default:
            return state;
    }
}